<template>
    <div class="container about">
        <div class="columns">
            <div class="column is-3 text-block">
                <h1 class="text-block--title">{{ $t(`about.textBlock.title`) }}</h1>

                <p class="text-block--text is-hidden-mobile">{{ $t(`about.textBlock.text`) }}</p>
            </div>
            <div class="column is-8 is-offset-1 course-text">
                <div class="columns">
                    <div class="column is-12">
                        <p v-html="$t(`about.1`)">
                        </p>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-7 vertical-centered">
                        <p v-html="$t(`about.2`)"></p>
                    </div>
                    <div class="column is-4 is-offset-1">
                        <img src="../assets/images/dave.jpeg" :alt="$t(`about.img.photo`)">
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <p v-html="$t(`about.3`)"></p>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <router-link :to="{path: '/courses'}"><button class="loup-button course-button"><b>{{ $t(`about.button`) }}</b></button></router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-8 is-offset-4 course-text">
                <div class="columns">
                    <div class="column is-12">
                        <h2 v-html="$t(`about.theName`)"></h2>
                        <p v-html="$t(`about.aboutTheName`)"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style lang="scss" scoped>
    .container {
        background-image: url("../assets/shapes/background-watermark.svg");
        background-repeat: no-repeat;
        background-size: 83% 100%;
        background-position: right;
    }

    .text-block {
        align-self: unset;
        margin-top: 20vh;
    }

    .course-text {
        padding-top: 0;
    }

    .course-text p {
        font-family: 'Avenir', sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
        color: #033232;
    }

    .vertical-centered {
        display: flex;
        align-items: center;
    }

    .course-text h2 {
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
        color: #033232;
        line-height: 36px;
        font-size: 22px;
        display: flex;
        flex-direction: row;
    }
</style>
